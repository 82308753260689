import React from 'react';
import Main from '../common/Main';

export default function NotFoundPage() {
  return (
    <Main>
      Oops... 404
    </Main>
  );
}
